import React from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { useDevice } from 'device'
import { constants, getTradingItem, getAddItemProduct } from 'helpers'
import { getTradingItemDiscount } from 'helpers/getters'
import { useFt } from 'hooks'
import { useCnstrcProductTrackingDataAttrs } from 'modules/constructorIO'

import type { Money, Label } from 'typings/graphql'

import { Text, Rating } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import ProductPrice from 'compositions/ProductPrice/ProductPrice'
import ProductImage from 'compositions/ProductImage/ProductImage'
import AddToButton, {
  type AddToButtonProps,
  type AddToButtonPropsWithoutProduct,
} from 'compositions/buttons/AddToButton/AddToButton'

import Labels from '../components/Labels/Labels'
import RetailPrice from '../components/RetailPrice/RetailPrice'


export type ProductData = ProductFragment.Base & {
  prices?: {
    retail: Money
  }
}

export type ProductCardSmallProps = {
  className?: string
  data: ProductData
  buttonProps: AddToButtonPropsWithoutProduct
  withoutLink?: boolean
  position?: number
  // TODO: We need to replace `isEcommerce` flag with a something more flexible (PF-1347) added on 04–07–2022 by algeas
  isEcommerce?: boolean
  withEcommercePrefix?: boolean
  replaceRatingWithRetailPrice?: boolean
  excludeLabels?: Label[]
  lazy?: boolean
  bgColorClassName?: 'bg-light-beige' | 'bg-white'
  onProductClick?: (trackParams?: object) => void
  onProductLinkClick?: (product: ProductFragment.Base, volume: string, price?: Money, ) => void
  dataAttributes?: Record<string, any>
  withTradingItemImage?: boolean
  withProductPrice?: boolean
}

const ProductCardSmall: React.FunctionComponent<ProductCardSmallProps> = (props) => {
  const { className, data, buttonProps, position, withoutLink, replaceRatingWithRetailPrice,
    isEcommerce, withEcommercePrefix = true, excludeLabels, bgColorClassName = 'bg-light-beige',
    lazy, onProductClick, onProductLinkClick, dataAttributes, withProductPrice = true, withTradingItemImage } = props
  const { url, image, rebrandImage, brandInfo: { name: brand }, name, fullName, upchargePrice, rebrandLabels, rating, prices, typeGroup } = data

  const { tradingItem, isEcommerceProduct } = getTradingItem({
    product: data,
    isEcommerce,
    isFullSizeBottlePrioritized: isEcommerce,
  })

  const intl = useIntl()

  const isExtraProduct = typeGroup === 'PerfumeCase' || typeGroup === 'FragranceStorageBox'

  const isProductSaleBadgeEnabled = useFeatureIsOn(constants.features.productSaleBadge)
  const isHideCasesReviewsEnabled = useFt(constants.features.hideCasesReviews)
  const isRatingVisible = !isExtraProduct || !isHideCasesReviewsEnabled

  const { volume, unit } = tradingItem?.volume || {}
  let productUrl = withEcommercePrefix && isEcommerceProduct && typeGroup !== 'GiftSet' ? `/ecommerce${url}` : url
  const productName = unit && unit !== 'pcs' && isEcommerceProduct ? `${name} · ${volume} ${unit}` : name

  let addToButtonProps: Partial<AddToButtonProps> = {
    type: isEcommerceProduct ? 'cart' : 'queue',
    product: getAddItemProduct({
      product: data,
      tradingItem,
      intl,
    }),
  }

  const { isMobile } = useDevice()

  const cnstrcTrackingDataAttrs = useCnstrcProductTrackingDataAttrs({
    id: data.uid,
    name: data.name,
    prices: tradingItem?.prices,
    variationId: tradingItem?.sku,
  })

  const linkClickHandler = () => {
    if (typeof onProductClick === 'function') {
      onProductClick({
        position,
        action: 'link',
      })
    }

    if (typeof onProductLinkClick === 'function') {
      onProductLinkClick(
        data,
        volume ? `${volume} ${unit}` : '',
        tradingItem?.prices?.discountPrice
      )
    }
  }

  const isWhite = bgColorClassName === 'bg-white'

  const topContent = (
    <>
      <ProductImage
        src={withTradingItemImage && tradingItem.image || rebrandImage || image}
        alt={fullName}
        bgColor={isWhite ? 'white' : 'light-beige'}
        remWidth={isMobile ? 180 : 152}
        lazy={lazy}
      />
      <Text className="mt-8 line-clamp-2 break-words" message={brand} style="h8" color="gold-50" data-testid="productBrand" />
      <Text className="mt-4 line-clamp-2 break-words " message={productName} style="p4" data-testid="productName" />
      {
        replaceRatingWithRetailPrice ? (
          <RetailPrice className="mt-12" price={prices?.retail} />
        ) : (
          isRatingVisible && (
            <Rating className="mx-auto mt-12" value={rating?.avgRate} size={14} />
          )
        )
      }
    </>
  )

  const rootClassName = cx(className, 'relative flex flex-col justify-between p-12 text-center', !buttonProps && 'pb-32', bgColorClassName)
  const discount = isProductSaleBadgeEnabled && isEcommerceProduct && tradingItem && withProductPrice
    ? getTradingItemDiscount(tradingItem)
    : 0

  return (
    <div
      className={rootClassName}
      data-testid="productCardSmall"
      {...cnstrcTrackingDataAttrs}
      {...dataAttributes}
    >
      <Labels
        labels={rebrandLabels}
        excludeLabels={excludeLabels}
        upchargePrice={upchargePrice}
        isEcommerce={isEcommerceProduct}
        discount={discount}
        isSmallCard
        tradingItemType={tradingItem?.type}
      />
      {
        !withoutLink && url ? (
          <Href className="flex-auto" to={productUrl} onClick={linkClickHandler}>
            {topContent}
          </Href>
        ) : (
          <div className="flex-auto">
            {topContent}
          </div>
        )
      }
      {
        isEcommerceProduct && withProductPrice && (
          <ProductPrice className="mb-8 mt-16" data={tradingItem?.prices} uid={data.uid} />
        )
      }
      {
        Boolean(buttonProps) && (
          <AddToButton
            className="mt-16"
            size={38}
            fullWidth
            {...addToButtonProps}
            {...buttonProps as any}
            onClick={() => {
              if (typeof onProductClick === 'function') {
                onProductClick({ position })
              }

              if (typeof buttonProps.onClick === 'function') {
                buttonProps.onClick()
              }
            }}
          />
        )
      }
    </div>
  )
}


export default React.memo(ProductCardSmall)
