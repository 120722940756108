import React from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useIntl } from 'intl'

import { constants, getTradingItem, getAddItemProduct } from 'helpers'
import { getTradingItemDiscount } from 'helpers/getters'
import { useFt } from 'hooks'
import { useCnstrcProductTrackingDataAttrs } from 'modules/constructorIO'

import type { Money } from 'typings/graphql'
import type { Label } from 'typings/graphql'

import { Text, Rating, OfferLabel } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import ProductPrice from 'compositions/ProductPrice/ProductPrice'
import ProductImage from 'compositions/ProductImage/ProductImage'
import AddToButton, { type AddToButtonPropsWithoutProduct } from 'compositions/buttons/AddToButton/AddToButton'

import Labels from '../components/Labels/Labels'
import Notes from '../components/Notes/Notes'
import RetailPrice from '../components/RetailPrice/RetailPrice'
import productCardMessages from '../messages'


export type ProductData = ProductFragment.Detailed & {
  fragranceFamilies?: {
    name: string
  }[]
  prices?: {
    retail: Money
  }
}

export type ProductCardDetailedProps = {
  className?: string
  bgColorClassName?: 'bg-light-beige' | 'bg-white'
  data: ProductData
  buttonProps: AddToButtonPropsWithoutProduct
  position?: number
  withoutLink?: boolean
  withoutNotes?: boolean
  // TODO: We need to replace `isEcommerce` flag with a something more flexible (PF-1347) added on 04–07–2022 by algeas
  isEcommerce?: boolean
  withEcommercePrefix?: boolean
  withProductPrice?: boolean
  withDescription?: boolean
  withForwardedTradingItemUid?: boolean
  withCursorPointer?: boolean
  withTradingItemImage?: boolean
  replaceRatingWithRetailPrice?: boolean
  excludeLabels?: Label[]
  lazy?: boolean
  onProductClick?: (trackParams?: object) => void
  onProductLinkClick?: (product: ProductFragment.Base, volume: string, price?: Money, ) => void
  onCardClick?: () => void
  dataAttributes?: Record<string, any>
}

const ProductCardDetailed: React.FunctionComponent<ProductCardDetailedProps> = (props) => {
  const {
    className, bgColorClassName = 'bg-light-beige', data, buttonProps, position, withoutLink, replaceRatingWithRetailPrice,
    isEcommerce, withEcommercePrefix = true, withProductPrice = true, withDescription = true, withCursorPointer, excludeLabels, withoutNotes,
    withForwardedTradingItemUid = false, withTradingItemImage, lazy, onProductClick, onProductLinkClick, onCardClick, dataAttributes,
  } = props

  const {
    url, image, rebrandImage, brandInfo: { name: brand }, name, fullName,
    description, upchargePrice, rating, notes, fragranceFamilies,
    rebrandLabels, prices, typeGroup,
  } = data

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryCardsFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryCards)
  const isHideCasesReviewsEnabled = useFt(constants.features.hideCasesReviews)
  const isProductSaleBadgeEnabled = useFeatureIsOn(constants.features.productSaleBadge)

  const isAiSummaryCardsEnabled = isAiSummaryEnabled && isAiSummaryCardsFeatureEnabled

  const { isMobile } = useDevice()
  const intl = useIntl()

  const isWhite = bgColorClassName === 'bg-white'

  const { tradingItem, isEcommerceProduct } = getTradingItem({
    product: data,
    isEcommerce,
    isFullSizeBottlePrioritized: isEcommerce,
  })

  const isExtraProduct = typeGroup === 'PerfumeCase' || typeGroup === 'FragranceStorageBox'

  const isNotesVisible = !withoutNotes && Boolean(notes?.length)
  const isRatingVisible = !isExtraProduct || !isHideCasesReviewsEnabled
  const hasAiSummary = isAiSummaryCardsEnabled && true // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid

  const addToButtonProps = {
    type: isEcommerceProduct ? 'cart' as const : 'queue' as const,
    product: getAddItemProduct({
      product: data,
      tradingItem,
      intl,
    }),
  }

  const cnstrcTrackingDataAttrs = useCnstrcProductTrackingDataAttrs({
    id: data.uid,
    name: data?.name,
    prices: tradingItem?.prices,
    variationId: tradingItem?.sku,
  })

  const linkClickHandler = () => {
    if (typeof onProductClick === 'function') {
      onProductClick({
        position,
        action: 'link',
      })
    }

    if (typeof onProductLinkClick === 'function') {
      onProductLinkClick(
        data,
        volume ? `${volume} ${unit}` : '',
        tradingItem?.prices?.discountPrice
      )
    }
  }

  const productUrl = (() => {
    let finalUrl = url

    if (withForwardedTradingItemUid) {
      const hasQuery = /\?[^?]+$/.test(finalUrl)
      finalUrl = `${finalUrl}${hasQuery ? '&' : '?'}forwardedTradingItemUid=${tradingItem?.uid}`
    }

    if (withEcommercePrefix && isEcommerceProduct && typeGroup !== 'GiftSet') {
      finalUrl = `/ecommerce${finalUrl}`
    }

    return finalUrl
  })()

  const { volume, unit } = tradingItem?.volume || {}
  const productName = unit && unit !== 'pcs' && isEcommerceProduct ? `${name} · ${volume} ${unit}` : name

  const topContent = (
    <>
      <ProductImage
        src={withTradingItemImage && tradingItem.image || rebrandImage || image}
        alt={fullName}
        bgColor={isWhite ? 'white' : 'light-beige'}
        lazy={lazy}
        remWidth={320}
      />
      <Text className="mt-8 line-clamp-2" message={brand} style="h6" color="gold-50" data-testid="productBrand" />
      <Text className="mt-4 line-clamp-2" message={productName} style="p3" data-testid="productName" />
      {
        replaceRatingWithRetailPrice ? (
          <RetailPrice className="mt-16" price={prices?.retail} />
        ) : (
          isRatingVisible && (
            <Rating className="mx-auto mt-16" value={rating.avgRate} size={14} />
          )
        )
      }
    </>
  )

  const discount = isProductSaleBadgeEnabled && isEcommerceProduct && withProductPrice && tradingItem
    ? getTradingItemDiscount(tradingItem)
    : 0

  return (
    <div
      className={cx(className, 'relative flex flex-col justify-between text-center', bgColorClassName)}
      data-testid="productCardDetailed"
      {...cnstrcTrackingDataAttrs}
      {...dataAttributes}
    >
      <div className={cx('px-24 pt-16', withCursorPointer && 'cursor-pointer')} onClick={onCardClick}>
        <Labels
          labels={rebrandLabels}
          excludeLabels={excludeLabels}
          upchargePrice={upchargePrice}
          isEcommerce={isEcommerceProduct}
          discount={discount}
          tradingItemType={tradingItem?.type}
        />
        {
          !withoutLink && url ? (
            <Href to={productUrl} onClick={linkClickHandler}>
              {topContent}
            </Href>
          ) : (
            topContent
          )
        }
        {
          Boolean(fragranceFamilies?.length) && (
            <div className="mt-16">
              {
                fragranceFamilies.map(({ name }) => (
                  <OfferLabel className="mx-4 mt-4" key={name} title={name} bgColor="pink" />
                ))
              }
            </div>
          )
        }
        {
          isNotesVisible && (
            <Notes
            // ATTN mirror desktop styles if product cards will be used in row (carousel)
              className={cx('mt-16 flex-none', !isMobile && 'min-h-[94rem]')}
              items={notes}
              bgColor={isWhite ? 'white' : 'light-beige'}
            />
          )
        }
        {
          isEcommerceProduct && withProductPrice ? (
            <ProductPrice
              className="mb-8 mt-32 flex flex-auto items-end justify-center"
              uid={data.uid}
              data={tradingItem?.prices}
            />
          ) : (
            withDescription && (
              <>
                <Text
                  // Required for safari, includes 16rem of padding
                  className="mt-auto line-clamp-3 max-h-[76rem] pt-16"
                  message={description || ''} // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid
                  messageTag="div"
                  style="p3"
                  html
                />
                {
                  hasAiSummary && (
                    <Text className="mt-8" message={productCardMessages.aiSummaryNote} align="center" color="gray-50" style="p6" />
                  )
                }
              </>
            )

          )
        }
      </div>
      <div className="px-24 pb-24">
        {
          Boolean(buttonProps) && (
            <AddToButton
              className="mt-24 w-full flex-none"
              size={48}
              {...addToButtonProps}
              {...buttonProps}
              onClick={() => {
                if (typeof onProductClick === 'function') {
                  onProductClick({
                    position,
                    action: 'Add to cart',
                  })
                }

                if (typeof buttonProps.onClick === 'function') {
                  buttonProps.onClick()
                }
              }}
            />
          )
        }
      </div>
    </div>
  )
}


export default React.memo(ProductCardDetailed)
